<template>
  <base-layout>
    <add-edit-tax-plan />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div class="">
            <title-plus
              :title="$t('components.taxPlanManagement.headline')"
              @plus="add"
            />
          </div>
          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="handleApplyDate($event)"
              @cancel-date="handleCancelDate($event)"
            />
          </div>
        </div>

        <div
          class="flex flex-col px-4 mt-5 space-y-5 lg:items-center lg:space-y-0 lg:space-x-5 lg:flex-row mb-10"
        >
          <SummaryCard
            :title="$t('components.taxPlanManagement.summary.numberOfTaxes')"
            :value="indexMetaData.summary.total"
            variant="gray"
          />
        </div>
      </div>

      <div
        v-if="$acl.canNotView('Tax Plans')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`TaxPlanIndex`"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.name" />

            <FSTableRowItem :text="item.description || '--'" />

            <FSTableRowItem> {{ item.percentage }}% </FSTableRowItem>

            <FSTableRowItem :text="item.created_at" date />

            <FSTableRowItem>
              <x-status
                :variant="item.status_active ? 'green' : 'gray'"
                :text="item.status_active ? 'Active' : 'Inactive'"
                size="small"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <div class="flex items-center">
                <oto-edit-icon @click="edit(item)" />
              </div>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import XStatus from '@/components/badge/XStatus'

import { EventBus } from '@/utils/EventBus'
import { TaxConfig } from '@/config/TaxConfig'

import SummaryCard from '@/components/cards/SummaryCard'
import AddEditTaxPlan from './TaxPlanAddEdit'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'ViewTaxPlan',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,
    OtoEditIcon,
    DateRangePicker,
    SummaryCard,
    XStatus,
    AddEditTaxPlan,
    FSTable,
    FSTableRow,
    FSTableRowItem,
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          total: 0,
        },
      },
      indexDataEndpoint: TaxConfig.api.index,
      tableHeaders: [
        {
          text: `${this.$t('components.taxPlanManagement.table.columns.id')}`,
          width: '10%',
          field: 'name',
          sort: false,
          order: '',
        },
        {
          text: `${this.$t('components.taxPlanManagement.table.columns.name')}`,
          width: '15%',
          field: 'name',
          sort: false,
          order: '',
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.description'
          )}`,
          width: '25%',
          field: 'description',
          sort: false,
          order: '',
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.percentage'
          )}`,
          width: '15%',
          field: 'percentage',
          sort: false,
          order: '',
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.createdAt'
          )}`,
          width: '20%',
          field: 'created_at',
          sort: true,
          order: '',
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.status'
          )}`,
          width: '20%',
          field: 'status_active',
          sort: true,
          order: '',
        },
        {
          text: `${this.$t(
            'components.taxPlanManagement.table.columns.actions'
          )}`,
          width: '15%',
          field: null,
          sort: null,
          order: '',
        },
      ],
    }
  },
  methods: {
    onApplyFilterDateRange,
    add: function() {
      EventBus.$emit(TaxConfig.events.editingData, {})
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
    edit: function(item) {
      EventBus.$emit(TaxConfig.events.editingData, item)
      dispatchEvent(new Event(TaxConfig.events.sorToggle))
    },
  },
  async created() {
    // todo: remove the event listener on destroyed hook
    window.addEventListener(TaxConfig.events.refresh, () => {
      this.getIndexData(this.getQueryString)
    })
  },
}
</script>
